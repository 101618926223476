import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="calendar-sync-preview"
export default class CalendarSyncPreviewController extends Controller<HTMLElement> {
  static targets: string[] = [
    "calendar",
    "ownerEventVisibilitySummary",
    "ownerEventVisibilityDescription",
    "ownerEventVisibilityLocation",
    "othersEventVisibilitySummary",
    "othersEventVisibilityDescription",
    "othersEventVisibilityLocation",
  ];

  declare readonly hasCalendarTarget: boolean;
  declare readonly calendarTargets: HTMLElement[];

  declare readonly hasOwnerEventVisibilitySummaryTarget: boolean;
  declare readonly ownerEventVisibilitySummaryTarget: HTMLElement;

  declare readonly hasOwnerEventVisibilityDescriptionTarget: boolean;
  declare readonly ownerEventVisibilityDescriptionTarget: HTMLElement;

  declare readonly hasOwnerEventVisibilityLocationTarget: boolean;
  declare readonly ownerEventVisibilityLocationTarget: HTMLElement;

  declare readonly hasOthersEventVisibilitySummaryTarget: boolean;
  declare readonly othersEventVisibilitySummaryTarget: HTMLElement;

  declare readonly hasOthersEventVisibilityDescriptionTarget: boolean;
  declare readonly othersEventVisibilityDescriptionTarget: HTMLElement;

  declare readonly hasOthersEventVisibilityLocationTarget: boolean;
  declare readonly othersEventVisibilityLocationTarget: HTMLElement;

  colorUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateColor(inputElement);
  }

  _updateColor(inputElement: HTMLInputElement) {
    const textColorValue =
      inputElement.dataset.calendarSyncPreviewTextColorValue;
    const backgroundColorValue =
      inputElement.dataset.calendarSyncPreviewBackgroundColorValue;

    if (textColorValue && backgroundColorValue && this.hasCalendarTarget) {
      this.calendarTargets.forEach((calendarTarget) => {
        calendarTarget.style.color = textColorValue;
        calendarTarget.style.backgroundColor = backgroundColorValue;
      });
    }
  }

  summaryUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateSummary(inputElement);
  }

  _updateSummary(inputElement: HTMLInputElement) {
    const ownerEventVisibilitySummaryValue =
      inputElement.dataset.calendarSyncPreviewOwnerEventVisibilitySummaryValue;
    const othersEventVisibilitySummaryValue =
      inputElement.dataset.calendarSyncPreviewOthersEventVisibilitySummaryValue;

    if (
      ownerEventVisibilitySummaryValue != undefined &&
      this.hasOwnerEventVisibilitySummaryTarget
    ) {
      this.ownerEventVisibilitySummaryTarget.textContent =
        ownerEventVisibilitySummaryValue;
    }

    if (
      othersEventVisibilitySummaryValue != undefined &&
      this.hasOthersEventVisibilitySummaryTarget
    ) {
      this.othersEventVisibilitySummaryTarget.textContent =
        othersEventVisibilitySummaryValue;
    }
  }

  descriptionUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateDescription(inputElement);
  }

  _updateDescription(inputElement: HTMLInputElement) {
    const ownerEventVisibilityDescriptionValue =
      inputElement.dataset
        .calendarSyncPreviewOwnerEventVisibilityDescriptionValue;
    const othersEventVisibilityDescriptionValue =
      inputElement.dataset
        .calendarSyncPreviewOthersEventVisibilityDescriptionValue;

    if (
      ownerEventVisibilityDescriptionValue != undefined &&
      this.hasOwnerEventVisibilityDescriptionTarget
    ) {
      this.ownerEventVisibilityDescriptionTarget.textContent =
        ownerEventVisibilityDescriptionValue;
    }

    if (
      othersEventVisibilityDescriptionValue != undefined &&
      this.hasOthersEventVisibilityDescriptionTarget
    ) {
      this.othersEventVisibilityDescriptionTarget.textContent =
        othersEventVisibilityDescriptionValue;
    }
  }

  locationUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateLocation(inputElement);
  }

  _updateLocation(inputElement: HTMLInputElement) {
    const ownerEventVisibilityLocationValue =
      inputElement.dataset.calendarSyncPreviewOwnerEventVisibilityLocationValue;
    const othersEventVisibilityLocationValue =
      inputElement.dataset
        .calendarSyncPreviewOthersEventVisibilityLocationValue;

    if (
      ownerEventVisibilityLocationValue != undefined &&
      this.hasOwnerEventVisibilityLocationTarget
    ) {
      this.ownerEventVisibilityLocationTarget.textContent =
        ownerEventVisibilityLocationValue;
    }

    if (
      othersEventVisibilityLocationValue != undefined &&
      this.hasOthersEventVisibilityLocationTarget
    ) {
      this.othersEventVisibilityLocationTarget.textContent =
        othersEventVisibilityLocationValue;
    }
  }
}
